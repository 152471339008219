<template>
  <div class="row">
    <div class="col">
      <b-card title="Companies">
        <blueprint-data api-route-path="companies" :fields="fields">
          <template v-slot:address="{ item }">
            {{ item.street }}<span v-if="item.city && item.city !== '' || item.zip && item.zip !== ''">,</span>
            {{ item.city }} {{ item.zip }}<span v-if="item.state && item.state !== ''">,</span>
            {{ item.state }}<span v-if="item.country && item.country !== ''">,</span>
            {{ item.country }}
          </template>
          <template v-slot:default="{ state, item }">
            <b-form-group label-cols-sm="4" label-cols-lg="3" label="Name" label-for="name">
              <b-form-input id="name" v-model="item.name" :state="state('name')" type="text" placeholder="Name" />
            </b-form-group>
            <b-form-group label-cols-sm="4" label-cols-lg="3" label="Reg. No" label-for="regno">
              <b-form-input id="regno" v-model="item.regno" :state="state('regno')" type="text" placeholder="Reg. no" />
            </b-form-group>
            <b-form-group label-cols-sm="4" label-cols-lg="3" label="VAT No" label-for="vatno">
              <b-form-input id="vatno" v-model="item.vatno" :state="state('vatno')" type="text" placeholder="VAT no" />
            </b-form-group>
            <b-form-group label-cols-sm="4" label-cols-lg="3" label="Street">
              <b-form-input id="street" v-model="item.street" :state="state('street')" type="text" placeholder="Street" />
            </b-form-group>
            <b-form-group label-cols-sm="4" label-cols-lg="3" label="City">
              <b-form-input id="city" v-model="item.city" :state="state('city')" type="text" placeholder="City" />
            </b-form-group>
            <b-form-group label-cols-sm="4" label-cols-lg="3" label="Zip code">
              <b-form-input id="zip" v-model="item.zip" :state="state('zip')" type="text" placeholder="Zip" />
            </b-form-group>
            <b-form-group label-cols-sm="4" label-cols-lg="3" label="State">
              <b-form-input id="state" v-model="item.state" :state="state('state')" type="text" placeholder="State" />
            </b-form-group>
            <b-form-group label-cols-sm="4" label-cols-lg="3" label="Country">
              <b-form-input id="country" v-model="item.country" :formatter="toUpperCase" :state="state('country')" type="text" placeholder="ISO 3166 Country Code" />
            </b-form-group>
          </template>
        </blueprint-data>
      </b-card>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      errors: {},
      fields: [
        {
          key: 'name',
          sortable: true,
        },
        {
          key: 'regno',
          sortable: true,
        },
        {
          key: 'vatno',
          sortable: true,
        },
        {
          key: 'address',
          sortable: true,
          slot: true,
        },
      ],
    };
  },
  methods: {
    toUpperCase (value) {
      return value.toUpperCase();
    },
  }
};
</script>
